import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router} from '@angular/router';
import { CreditService } from '../../../services/credit.service';

@Component({
  selector: 'app-aprobado-medio',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: './aprobado-medio.component.html',
  styleUrl: './aprobado-medio.component.css'
})
export class AprobadoMedioComponent implements OnInit, OnDestroy{
  creditService = inject(CreditService);
  onVerSucursales = output<boolean>();
  onIrAWhatsapp = output<boolean>();
  medio = 0;
  codigoRespuesta = '';
  msnWhatsapp = 'Hola, quiero desembolsar mi crédito';
  telef = this.creditService.phoneWhatsapp;

  constructor(private router: Router){
      console.log('medio1: ', this.medio);
  }

  /**
   * codigo
   * WS-OK
   * WS-SL
   * WS-PR
   * RO-015
   */
  ngOnInit(): void {
    console.log('medio2: ', this.medio);
    
    if(this.creditService.codigoRespuesta !== 'WS-OK'){
      this.router.navigateByUrl('/credito');
    }
    else{
      console.log('medio3: ', this.medio);
      this.medio = this.creditService.medio;
      //oculta el whatsapp para acceder solo desde el boton correspondiente
      const element = document.querySelector('.btn-wsp') as HTMLElement;
  
      if (element) {
        element.style.display = 'none';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.classList.add('footer-absolute');
      }
    }

  }

  ngOnDestroy(): void {
    const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.classList.remove('footer-absolute');
      }
  }
}
